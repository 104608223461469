// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-danke-js": () => import("../src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-datenschutz-js": () => import("../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mitmachen-js": () => import("../src/pages/mitmachen.js" /* webpackChunkName: "component---src-pages-mitmachen-js" */),
  "component---src-pages-oldpages-wahl-2020-js": () => import("../src/pages/oldpages/wahl2020.js" /* webpackChunkName: "component---src-pages-oldpages-wahl-2020-js" */),
  "component---src-pages-ortsverband-js": () => import("../src/pages/ortsverband.js" /* webpackChunkName: "component---src-pages-ortsverband-js" */),
  "component---src-pages-politik-js": () => import("../src/pages/politik.js" /* webpackChunkName: "component---src-pages-politik-js" */),
  "component---src-pages-unserestadtrate-js": () => import("../src/pages/unserestadträte.js" /* webpackChunkName: "component---src-pages-unserestadtrate-js" */),
  "component---src-pages-unterstutzen-js": () => import("../src/pages/unterstützen.js" /* webpackChunkName: "component---src-pages-unterstutzen-js" */)
}

